* {
    margin: 0;
}

.imgContainer {
    max-height: 130px;
    width: 250px;
    border-radius: 3px;
    overflow: hidden;
    display: inline-block;
    background-color: #f6f7f8;
    margin-right: 10px;
    position: relative;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.row_poster_large {
    width: 200px !important;
    max-height: 300px !important;
}

.row_poster_large img {
    object-fit: cover;
    cursor: pointer;
}

.imgScroller {
    padding-left: 20px;
}

.preview {
  background-color: rgba(0, 0, 0, 1.0);
  color: #fff;
  padding-bottom: 20px;
  border-radius: 8px;
  width: 290px;
  height: 300px;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  cursor: pointer;
  font-family: myFont;
}

.preview p {
  font-size: 14px;
  font-family: myFont;
}

.div_description {
    font-family: myCR !important;
    position: absolute;
    top: 200px;
    left: 0;
}

.div_description1 {
    color: white;
}

.vBtn {
    position: absolute;
    top: 140px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgContainer:hover .popup {
  display: block; /* Show the popup on hover */
}

.title {
    font-weight: 600;
    font-size: 20px;
    padding-left: 20px;
    font-family: myFont;
    word-spacing: 20px;
}

.imgScroller::-webkit-scrollbar {
    display: none;
}

.row_video1 {
    width: 100%;
    height: 220px;
    object-fit: fill;
    z-index: -1;
    pointer-events: none;
    padding: 0 !important;
    overflow-y: hidden;
    position: absolute;
    top: -250px;
    left: 0;
}

.preview2 {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 1.0);
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
    width: 800px;
    height: 600px;
    z-index: 9999;
    cursor: pointer;
    overflow-y: scroll;
    font-family: myFont;
}

.banner1 {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    color: white;
    object-fit: contain;
    height: 400px;
    z-index: 1;
}

.banner1 .banner__title {
    font-size: 30px;
    font-family: myFont;
}

.close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: none;
    z-index: 9999;
    padding: 9px 10px 0 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: black;
}

.close_btn btn {
    background-color: black;
}

.btn__row {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    margin-right: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    font-family: myFont;
    padding: 10px 20px !important;
    width: 130px;
}

p {
    font-family: myFont;
    overflow-y: hidden !important;
}

span {
    overflow-y: hidden;
}

.nav__div8 {
    flex: 0 0 200px;
    margin-right: 30px;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 40px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    color: gray;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
    transition: transform 0.2s ease-in-out;
    font-family: myFont;
}

.nav__div8:hover {
    transform: scale(0.97);
}

.smImg1 {
    height: 110px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}

.preview2::-webkit-scrollbar {
    display: none;
}

@media (max-width: 800px) {
    .preview2 {
        width: 600px;
        font-family: myFont;
    }

    .close_btn {
        position: absolute;
        top: 50px;
        right: 20px;
        cursor: none;
        z-index: 9999;
    }

    .nav__div8 {
        flex: 0 0 200px;
        font-family: myFont;
    }

    .mp__div6 {
        display: block;
        padding: 5px 50px;
        font-family: myFont;
    }

    .mp__div8 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 60px;
        font-family: myFont;
    }

    .dBtn {
        margin-bottom: 20px;
        margin-left: 0 !important;
    }

    .banner1::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.61), #111);
    }
}

@media (max-width: 600px) {
    .container-fluid {
        width: 100vw;
        padding-left: 11px;
        font-family: myFont;
    }

    .title {
        padding-left: 25px;
        font-weight: 500;
        font-size: 15px;
    }

    .imgScroller {
        padding-left: 25px;
    }

    .row_poster_large {
        width: 100px !important;
        max-height: 150px !important;
    }

    .image:not(.row_poster_large) {
        width: 200px !important;
        max-height: 80px !important;
    }

    .preview2__div {
        display: none;
    }
}
