* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.lb__div1 {
	padding: 20px;
}

div {
	font-family: myFont;
}

.lb__div2 {
	margin-top: 150px;
}

tr {
	padding-top: 30px;
}

td {
	color: white;
	text-align: center;
}

.lb__div3 {
	display: flex;
	justify-content: center;
}

.lb__div3 div {
	flex: 0 0 100%;
	border: 1px solid grey;
	padding: 20px;
	border-radius: 20px;
	font-size: 14px;
}

.lb__div4 {
	display: none;
}

#navbar10 {
	display: none !important;
}

@media (max-width: 800px) {
	.lb__div2 {
		margin-top: 80px;
	}

	.lb__div4 {
		display: block;
	}

	.lv_div3 {
		margin-left: 50px;
	}

	table {
		display: none;
	}
}

@media (max-width: 600px) {
	.lb__div2 {
		margin-top: 55px;
	}

	#navbar10 {
		display: flex !important;
		position: fixed;
		bottom: 0;
		end: 0;
		width: 100%;
		padding: 10px 0;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-family: myFont;
		margin-left: -10px;
	}

	.lv_div3 {
		margin-left: 20px;
	}
}