* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

#payDiv1 {
	color: white;
	padding: 0;
	margin-top: 250px;
}

.nav__logo {
  width: 70px;
  height: 55px;
}

.nav__link {
	border: none;
	background-color: transparent;
	text-decoration: none;
	color: white;
	transition: all 0.5s;
}

.nav__link:hover {
	color: white;
	transform: scale(1.1);
}

.nav__avatar {
  width: 35px;
}

#navbar1 {
	background-color: black;
}

@media (max-width: 375px) {
	#payDiv1 {
		margin-top: 200px;
	}
}