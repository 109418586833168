* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

#pay__div1 {
	height: 100vh;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: myFont;
}

#pay__div1::-webkit-scrollbar {
  display: none;
}

.amtBtn {
	padding: 20px 70px;
	border: 1px solid grey;
	margin-right: 20px;
	border-radius: 5px;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px; /* Add some margin between marquee texts */
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee-animation 10s linear infinite;
}

.sol__div2 {
	display: none;
}

.pav__div2 {
	padding: 30px;
	border: 1px solid grey;
	height: 100%;
	margin-top: 0px !important;
}

.mobile__div {
	display: none;
}

@keyframes marquee-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 600px) {
	.sol__div2 {
		display: block;
		margin-left: -10px;
	}

	.sol__div1 {
		display: none;
	}

	#pay__div1 {
	}

	.pay__div2 {
		padding: 5px;
		border-bottom: 0;
		margin-top: 100px;
	}

	.donate__div {
		margin-top: 100px;
	}

	.donate__btn__div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.mobile__div {
		display: inline-block;
		height: 40px;
	}
}