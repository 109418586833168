* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.play__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0 !important;
  transform: scale(1,1);
}

.skip__btn {
  position: absolute;
  bottom: 50px;
  right: 20px;
  background-color: black;
  padding: 10px 20px;
  color: white;
  z-index: 9999;
  border: 1px solid grey;
  border-radius: 5px;
  cursor: pointer;
}

#play {
  visibility: hidden;
}

progress[value] {
  appearance: none; /* Needed for Safari */
  border: none; /* Needed for Firefox */
  color: transparent; /* Fallback to a solid color */
  visibility: hidden;
}

/* WebKit styles */
progress[value]::-webkit-progress-value {
  background-image: none;
}

/* Firefox styles */
progress[value]::-moz-progress-bar {
  background-image: none;
}

@media (max-width: 600px) {
  .skip__btn {
    position: absolute;
    bottom: 200px;
    right: 10px;
  }
}