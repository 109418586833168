@font-face {
	font-family: myFont;
	src: url('bad.ttf');
}

@font-face {
	font-family: myCR;
	src: url('cr.ttf');
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.mainContainer {
	background-color: #111;
	overflow-y: hidden;
}

#homeDiv1 {
	background-color: #111;
	height: 100vh;
	padding: 0 !important;
}

#navbar1 {
	display: none !important;
}

#navbar3 {
	display: none !important;
}

#navbar4 {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100vw;
	padding: 0 !important;
	overflow-x: hidden;
	background: rgba(0, 0, 0, 0.1);
  font-family: myFont;
}

.nav__black {
  background-color: #111 !important;
}

.nav__logo {
  width: 70px;
  height: 55px;
}

.nav__div {
	position: fixed;
	top: 10px;
	left: 100px;
	width: 1000px;
	overflow-y: hidden !important;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background: transparent;
	font-family: myFont;
}

.nav__link {
	border: none;
	background-color: transparent;
	text-decoration: none;
	color: white;
	transition: all 0.5s;
}

.nav__link:hover {
	color: white;
	transform: scale(1.1);
}

.nav__avatar {
  width: 35px;
}

@media (max-width: 800px) {
	.nav__div {
		left: 20px !important;
		width: 700px !important;
	}
}

@media (max-width: 600px) {
	.pDiv {
		padding-left: 8px;
	}

	.nav__div {
		display: none !important;
	}

	#navbar1 {
		display: flex !important;
		position: fixed;
		bottom: 0;
		end: 0;
		width: 100%;
		padding: 10px 0;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-family: myFont;
		margin-left: 1px;
	}

	#navbar3 {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0 !important;
		z-index: 9999;
		height: 40px;
		font-family: myFont;
	}

	.mh__div {
		display: none !important;
	}

	.nav__avatar {
	  width: 35px;
	}
}