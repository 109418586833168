* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

#mpDiv1 {
	overflow-x: hidden;
	font-family: myFont;
}

#headerDiv {
  	height: 500px; /* You must set a specified height */
  	background-position: center;
  	background-repeat: no-repeat;
  	background-size: cover;
  	padding: 0 !important;
  	width: 100%;
  	overflow: hidden;
}

#displayText {
	position: absolute;
	bottom: 100px;
	left: 20px;
	font-family: myFont;
}

#closeBtn1 {
	position: absolute;
	top: 10px;
	right: 30px;
	z-index: 9999;
	padding: 9px 10px 2px 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: black;
}

.mp__btn__div {
	padding: 7px 10px 2px 10px;
	width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	transition: all 0.5s ease;
}

.mp__btn__div:hover {
	transform: scale(1.07);
}

#cBtn {
	display: none;
}

.mp__text1 {
	font-size: 1.5rem;
	font-family: myFont;
}

.mp__text2 {
	font-size: 1.7rem;
	font-family: myFont;
}

.fade-in {
	position: relative;
}

#mpDiv5 {
	overflow-x: scroll !important;
	font-family: myFont;
}

.mp__div1 {
	display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100vw;
    scrollbar-width: none;    
    -ms-overflow-style: none;
    font-family: myFont;
}

.mp__div3 {
	display: none;
}

.nav__btn {
	flex: 0 0 auto;
	padding: 5px;
	margin-right: 20px;
	text-align: center;
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 500;
	align-items: center;
	minWidth: fit-content;
	cursor: pointer;
}

.sImg {
	height: 150px;
	object-fit: cover;
	border-radius: 5px;
	cursor: pointer;
}

.mp__div4 {
	display: none;
	font-family: myFont;
}

.nav__div1 {
	flex: 0 0 200px;
	margin-right: 10px;
}

.active {
	background-color: blueviolet;
  	color: white;
  	border-radius: 25px;
  	padding: 10px;
}

.mp__div1::-webkit-scrollbar {
    display: none;
}

.mp__div2::-webkit-scrollbar {
    display: none;
}

#mpDiv5::-webkit-scrollbar {
    display: none;
}

.nav__div5 {
	flex: 0 0 auto;
	margin-right: 10px;
	object-fit: cover;
	cursor: pointer;
}

.mp__div6, .mp__div9 {
	padding: 5px 100px;
}

.nav__div6 {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 25px;
	cursor: pointer;
	padding: 20px 0;
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
	transition: all 500ms;
}

.nav__div6:hover {
	transform: scale(1.06);
}

.mImg {
	width: 150px;
	height: 80px;
}

.mp--fadeBottom {
	position: absolute;
	bottom: 0;
	end: 0;
	height: 7.4rem;
	background-image: linear-gradient(
		180deg,
		transparent,
		rgba(37, 37, 37, 0.61),
		#111
	);
	z-index: 999;
}

.mp__div8 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: 60px;
}

.nav__div7 {
	flex: 0 0 230px;
	margin-right: 30px;
	object-fit: cover;
	cursor: pointer;
	margin-bottom: 40px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.1);
	color: gray;
	box-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
	transition: transform 0.2s ease-in-out;
}

.nav__div7:hover {
	transform: scale(0.97);
}

.smImg {
	height: 130px;
	object-fit: cover;
	border-radius: 5px;
	cursor: pointer;
}

.smText1 {
	padding: 10px;
}

.smText2 {
	padding: 0 10px 20px 10px;
	font-size: 15px;
}

.div_description {
    font-family: myCR !important;
    font-size: 16px;
    letter-spacing: 0.1px;
}

@media (max-width: 800px) {
	#headerDiv {
		height: 350px;
	}

	#mpDiv1 {
		height: 100vh;
		padding: 0 !important;
	}

	#mpDiv, #mpDiv3 {
		height: 300px;
	}

	#mpDiv2 {
		width: 300px;
	}

	#displayText {
		position: relative;
		bottom: 230px;
		left: 10px;
	}

	#closeBtn1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: none;
        z-index: 9999;
	}

	.dBtn, .cyBtn {
		margin-bottom: 20px;
		margin-left: 0 !important;
	}

	#mpDiv4 {
		margin-left: 5px;
	}

	.mp__div3 {
		display: flex;
	    overflow-x: scroll;
	    white-space: nowrap;
	    width: 100vw;
	    scrollbar-width: none;    
	    -ms-overflow-style: none;
	}

	.mp__div3::-webkit-scrollbar {
    	display: none;
	}

	.mp__div6, .mp__div9 {
		display: none !important;
	}

	.mp__div4 {
		display: flex;
	    overflow-x: scroll;
	    white-space: nowrap;
	    width: 100vw;
	    scrollbar-width: none;    
	    -ms-overflow-style: none;
	}

	.mp__div4::-webkit-scrollbar {
    	display: none;
	}

	.mp__div7 {
		display: none;
	}
}

@media (max-width: 768px) {
	#displayText {
		position: relative;
		bottom: 140px;
		left: 10px;
	}

	#headerDiv::after {
	  content: "";
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  height: 50px; /* Adjust the height as needed */
	  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.61), #111);
	}
}

@media (max-width: 430px) {
	#closeBtn1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: none;
        z-index: 9999;
	}

	#displayText {
		position: relative;
		bottom: 170px;
		left: 10px;
	}

	.mp__div6 {
		display: none !important;
	}
}

@media (max-width: 390px) {
	#closeBtn1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: none;
        z-index: 9999;
	}

	#displayText {
		position: relative;
		bottom: 180px;
		left: 10px;
	}
}

@media (max-width: 320px) {
	#closeBtn1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: none;
        z-index: 9999;
	}

	#displayText {
		position: relative;
		bottom: 180px;
		left: 10px;
	}
}

@media (max-width: 300px) {
	#displayText {
		position: relative;
		bottom: 140px;
		left: 10px;
	}
}
