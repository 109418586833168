* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.navbar {
	width: 100%;
	color: white;
	font-size: 14px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.1);
	padding: 0;
}

.scrolled {
	background-color: black;
	z-index: 999;
}

.navbar .container-fluid {
	padding: 0 70px 0 10px;
	display: flex;
	justify-content: space-between;
}

.left, .right {
	display: flex;
	align-items: center;
	font-family: myFont;
}

.right {
	position: relative;
}

.navbar .container-fluid .left img {
	height: 100px;
}

.left Link {
	align-self: flex-end;
}

.right img {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	object-fit: cover;
	cursor: pointer;
}

.icon {
	padding: 0 15px;
	cursor: pointer;
}

.nav__link__div {
	color: white;
	padding: 0 15px;
	text-decoration: none;
}

.nav__link__div1 {
	color: white;
	text-decoration: none;
}

span {
	padding: 10px;
	cursor: pointer;
}

.options {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 999;
	border-radius: 5px;
	background-color: black;
	padding: 20px;
}

.options span {
	color: white;
}

.dropdown {
	margin-top: 5px;
}

.dropdown-toggle:hover {
	border: 0;
}

.nav_dropdown_menu {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	left: 20px;
	z-index: 9999;
	width: 800px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: start;
  	overflow-y: clip;
}

.nav_dropdown_menu .nav_dropdown_btn {
	width: auto;
	text-decoration: none;
	padding: 10px;
}

.nav_dropdown_btn {
	transition: all 0.3s ease-in;
}

.nav_dropdown_btn:hover {
	color: white;
	transform: scale(1.1);
}

.dsk__div, .cr__div {
	display: none;
} 

.mb_search_link {
	display: none;
}

@media (max-width: 975px) {
	.nav__search__div, .nav__name__div, .nav__bell_div {
		display: none;
	}

	.nav_dropdown_menu {
		width: 100%;
		position: absolute;
		top: 55px;
		left: 0;
	}
}

@media (max-width: 796px) {
	.navbar .container-fluid {
		padding: 0 2px;
	}

	.tv__div, .pre__div {
		display: none;
	}

	.coinValue {
		display: inline-block;
	}
}

@media (max-width: 768px) {
	.navbar .container-fluid {
		padding: 0 30px 0 0;
	}

	.dsk__div, .cr__div, .lb__div {
		display: inline-block;
		margin-right: 20px;
	}

	.search__div {
		display: inline-block; 
	}

	.nav_dropdown_menu {
		top: 80px;
	}
}

@media (max-width: 612px) {
	.navbar {
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 999;
	}

	.scrolled {
		background-color: black;
		z-index: 999;
	}

	.navbar .container-fluid {
		padding: 0 10px 0 0;
	}

	.nav__link__div {
		display: none;
	}

	.dsk__div, .cr__div, .lb__div, .x__div, .tele__div, .book__div, .tb_s_div {
		display: none;
	}

	.profile, .options {
		display: none;
	}

	.left {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.left .dropdown {
		padding: 0;
		margin-left: 20px;
	}

	.left .dropdown .dropdown_icon__btn {
		padding: 0;
	}

	.right {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.left, .right {
	    flex-grow: 1; /* This gives the right side more space */
	}

	.navbar .container-fluid .left img {
		height: 50px;
	}

	.right img {
		border-radius: 25px;
		height: 30px;
		width: 30px;
	}

	.search__div {
		display: inline-block;
		margin-left: 10px !important;
	}
}

@media (max-width: 430px) {
	.nav_dropdown_menu {
		top: 53px;
	}
}

@media (max-width: 425px) {
	.nav_dropdown_menu {
		top: 55px;
	}
}

@media (max-width: 320px) {
	.nav_dropdown_menu {
		width: 100%;
		position: absolute;
		top: 55px;
		left: 0;
	}

	.left .dropdown {
		padding: 0;
		margin-left: 5px;
	}
}