* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.p__div1 {
	height: 100vh;
	padding: 50px 50px 50px 10px;
	font-family: myFont;
}

.p__div5 {
	padding: 0 0 0 50px;
}

.p__div6 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: nowrap;
	overflow-x: scroll;
}

.p__div6::-webkit-scrollbar {
  display: none;
}

.p__div6 .card {
	margin-right: 35px;
	box-shadow: 0 0 9px white;
	cursor: pointer;
}

li {
	transition: none;
}

.pricing .list-group-item {
  transition: none !important;
}

.p__div4 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 30px;
	height: 450px;
}

.btn {
	padding: 10px 30px;
}

.p__div__price {
	font-weight: 800;
	padding-top: 10px;
	font-size: 22px;
}

@media (max-width: 1024px) {
	.p__div1 {
		padding: 0;
		margin-left: -15px;
	}

	.p__div2 h3 {
		margin-top: 30px;
	}

	.p__div5 {
		margin-left: 20px;
		padding: 0 30px 0 30px;
	}

	.p__div5 > :first-child {
		padding-left: 160px;
	}

	.p__div4 {
		display: flex;
		flex-direction: row;
		padding-top: 30px;
		overflow-x: scroll;
	}

	.p__div3 {
		flex: 0 0 450px;
		margin-right: 30px;
	}

	.p__div3:first-child {
		margin-left: 920px;
	}

	.p__div3:nth-last-child(3) {
		margin-left: 10px;
	}

	.p__div4::-webkit-scrollbar {
  	display: none;
	}
}

@media (max-width: 768px) {
	.p__div1 {
		padding: 0;
		margin-left: 0;
	}

	.p__div2 h3 {
		margin-top: 30px;
	}

	.p__div5 > :first-child {
		padding-left: 390px;
	}

	.p__div4 {
		display: flex;
		flex-direction: row;
		padding-top: 30px;
		overflow-x: scroll;
	}
}

@media (max-width: 500px) {
	.p__div1 {
		padding: 0;
		margin-left: -15px;
	}

	.p__div2 {
		margin-left: 10px;
	}

	.p__div5 {
		margin-left: 28px;
		padding: 0;
	}

	.p__div5 > :first-child {
		padding-left: 0;
	}

	.p__div6 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		overflow: scroll;
	}

	.p__div6 .card {
		margin-right: 0;
		margin-bottom: 50px;
	}
}